import React, { useContext } from 'react'
import NavBar from '../NavBar/NavBar'
import './Home.css'
import { AiFillHeart } from 'react-icons/ai'
import { AppContext } from '../../context/AppContext'

const Home = () => {
    const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };

    return (
        <div className='home' onClick={()=>{handleClick()}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
            </style>
            <div className='homebackground' >
                <div className='brandguidelines'>
                    <img src={require('../Assets/homefincitylogo.png')} alt='homefincity'></img>
                    <div className='brand-text'>
                        <h2>Brand Guidelines</h2>
                    </div>
                    <div className='welove-text'>
                        <h2>We Love <AiFillHeart className='heart-icon'></AiFillHeart> that Brand</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;