import React, { useContext, useEffect } from 'react'
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";
import './PhoneNumber.css'

const PhoneNumber = ({setPhoneNumberHeight, buttonsActive, myref}) => {
    const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="phoneNumberBackground section-height"
      id="phoneNumberBackground"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="phoneNumberelems">
        <div className="phoneNumber-helper">
          <div className="phoneNumberelems-field">
            <h1>Phone Number Field</h1>
            <div className="phoneNumberText">
              <p>
                1. Country Flags with dropdown should be used for phone number
                Fields.
              </p>
              <p>2. User Should not be able to input more than 10 digits. </p>
              <p>3. Numbers Keyboard should open up in mobile</p>
            </div>
            <img
              src={require("../Assets/phoneNumber/phone.png")}
              width="230px"
              height="56px"
            ></img>
          </div>
          <div className="phoneNumberelems-img">
            <img
              src={require("../Assets/phoneNumber/dimensions.png")}
              width="278px"
              height="180px"
            ></img>
          </div>
        </div>
        <div className="phoneNumberelems-otp">
          <h1>OTP</h1>
          <div className="phoneNumberText">
            <p>1. Avoid alphanumeric codes, Only Number Inputs.</p>
            <p>2. There should be split input fields. </p>
          </div>
          <img
            src={require("../Assets/phoneNumber/otp.png")}
            width="340px"
            height="255px"
          ></img>
        </div>
      </div>
    </section>
  );
}

export default PhoneNumber