import React, { useEffect } from "react";
import "./NavDropdown.css";

const NavDropdown = ({ onClickHandler }) => {

  return (
    <nav>
      <div className="navDropdown">
        <ul>
          <li className="thelogo active" onClick={(e) => onClickHandler(e, "thelogo")}>
            <a href="#thelogo" className="navDropdown-button">
              The logo
            </a>
          </li>
          <li className="UsesOnBackground" onClick={(e) => onClickHandler(e, "UsesOnBackground")}>
            <a href="#UsesOnBackground" className="navDropdown-button">
              Uses On Background
            </a>
          </li>
          <li className="LogoMisuse" onClick={(e) => onClickHandler(e, "LogoMisuse")}>
            <a href="#LogoMisuse" className="navDropdown-button">
              Logo Misuse
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavDropdown;
