import React, { useContext, useEffect, useState } from "react";
import "./NavBar.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import dimension from "../../dimension/dimension";
import { AppContext } from "../../context/AppContext";

const NavBar = () => {
  // const NavComp = useContext(ToggleContext)
  const [showlogo, setShowlogo] = useState("");
  const [showcomp, setShowcomp] = useState(false);
  const [value, setvalue] = useState({ height: "100%", width: "20%" });
  const [windowWidth, windowHeight] = dimension();
  const context = useContext(AppContext);
  const shownav = context?.appData?.toggleNavbar;

  useEffect(() => {
    let value =
      windowWidth > 1000
        ? { height: "100%", width: "20%" }
        : {
            height: !shownav ? "0" : "100%",
            width: !shownav ? "0" : "75%",
            transition: !shownav
              ? "width 200ms ease-in-out"
              : "width 200ms ease-out-in",
          };
    setvalue(value);
  }, [windowWidth, shownav]);

  function linkcollapse() {
    if (shownav == true) {
      context?.setAppData({
        ...context.appData,
        toggleNavbar: false,
      });
    }
  }

  function noDrop() {
    if (showlogo == true) {
      setShowlogo(false);
    }
    if (showcomp == true) {
      setShowcomp(false);
    }
  }

  return (
    <div className="main-bar" >
      <div className="nav-mobile">
        <div
          className="nav-button"
          onClick={() => {
            context?.setAppData({
              ...context.appData,
              toggleNavbar: !context.appData.toggleNavbar,
            });
          }}
        >
          <hr></hr>
          <hr></hr>
          <hr></hr>
        </div>
      </div>
      <div className="navbar" style={value}>
        <div className="navname">
          <Link
            to="/home"
            onClick={() => {
              linkcollapse();
            }}
          >
            <img
              src={require("../Assets/Fincity Logo.png")}
              width="88%"
              height="55px"
              alt="fincity"
            ></img>
          </Link>
        </div>
        <nav className="navelems">
          <ul className="navelem">
            <li>
              <NavLink
                to="/logo"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                The Finctiy Logo
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/colorpallete"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Color Pallete
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/typography"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Typography
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/spacing"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Spacing
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/layoutandgrids"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Layout & Grids
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/iconography"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Iconography
              </NavLink>
            </li>
            <hr />
            <li>
              <NavLink
                to="/buttons"
                className="navlinktag"
                onClick={() => {
                  linkcollapse();
                  noDrop();
                }}
              >
                Components
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
