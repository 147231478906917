import React, { useContext, useEffect } from 'react'
import './LogoMisuse.css'
import { AppContext } from '../../context/AppContext'
import dimensions from '../../dimension/dimensions';


const LogoMisuse = ({setLogoMisuseHeight}) => {
    const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };


    return (
        <section className='LogoMisuse section-height-logomisuse' id='LogoMisuse' onClick={()=>{handleClick()}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
            </style>
            
            <div className='LogoMisuse-background'>
                <h1>Logo Misuse</h1>
                <div className='LogoMisuse-elem'>
                    <div className='firstcontainer'>
                        <div className='column-1'>
                            <p>Do not crop the Logo</p>
                            <p>Do not change the transparency of the logo</p>
                            <p>Do not shuffle around the colors of the octothorpe</p>
                            <p>Do not distort the logo</p>
                        </div>
                        <div className='column-2'>
                            <img src={require('../Assets/LogoMisuse/Group46214.png')} width='78px' height='57px'></img>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-1.png')} width='228px' height='55px'></img>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-2.png')} width='228px' height='55px'></img>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-3.png')} width='159px' height='55px'></img>
                        </div>
                    </div>
                    <div className='secondcontainer'>
                        <div className='column-1'>
                            <p>Do not use drop shadows or any other effects</p>
                            <p>Do not re-create using any other typeface</p>
                            <p>Do not outline logotype</p>
                            <p>Do not rotate any part of the logo</p>
                        </div>
                        <div className='column-2'>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-4.png')} width='229px' height='75px'></img>
                            <div className='FincityLogo-5'>
                                <img src={require('../Assets/LogoMisuse/FincityLogo-5/Fincity Logo.png')} width='54px' height='47px'></img>
                                <img src={require('../Assets/LogoMisuse/FincityLogo-5/Fincity.png')} width='150px' height='65px'></img>
                            </div>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-6.png')} width='228px' height='55px'></img>
                            <img src={require('../Assets/LogoMisuse/FincityLogo-7.png')} width='233px' height='61px'></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LogoMisuse