import React, { useContext, useEffect } from "react";
import "./DataTable.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const DataTable = ({setDatatableHeight, datatableActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      ref={ref}
      className="DataTable section-height"
      id="DataTable"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="DataTable-background">
        <h1>DataTable</h1>
        <div className="DataTable-elem">
          <img
            className="DataTable-first"
            src={require("../Assets/Datatable/Group46304.png")}
          ></img>
          <img
            className="DataTable-second"
            src={require("../Assets/Datatable/Group46305.png")}
          ></img>
          <p>Specs</p>
          <img
            className="DataTable-third"
            src={require("../Assets/Datatable/Group46605.png")}
          ></img>
        </div>
      </div>
    </section>
  );
};

export default DataTable;
