import React, { useContext } from 'react'
import './Spacing.css'
import { AppContext } from '../../context/AppContext'


const Spacing = () => {
    const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };
    return (
        <div className='Spacing' onClick={()=>{handleClick()}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
            </style>
            <div className='spacing-background'>
                <h1>Spacing</h1>
                <hr className='titlebottomborder'></hr>
                <div className='spacing-elem'>
                    <div className='spacing-content'>
                        <p>
                            For indentation or spacing definition 8px is a good basic unit to work
                            with. The number 8 is easily multiplied and provides flexible and
                            consistent, yer distinct enough, steps between. Most measurements align
                            to an 8px grid applied, which aligns both spacing and the overall layout.
                        </p>
                    </div>
                    <div className='spacing-content-container'>
                        <div className='rectangle-size'>
                            <div className='rectangle-4'>
                                <div className='rectnagle-size-4'></div>
                                <p>4</p>
                            </div>
                            <div className='rectangle-8'>
                                <div className='rectnagle-size-8'></div>
                                <p>8</p>
                            </div>
                            <div className='rectangle-16'>
                                <div className='rectnagle-size-16'></div>
                                <p>16</p>
                            </div>
                            <div className='rectangle-24'>
                                <div className='rectnagle-size-24'></div>
                                <p>24</p>
                            </div>
                            <div className='rectangle-32'>
                                <div className='rectnagle-size-32'></div>
                                <p>32</p>
                            </div>
                            <div className='rectangle-40'>
                                <div className='rectnagle-size-40'></div>
                                <p>40</p>
                            </div>
                            <div className='rectangle-48'>
                                <div className='rectnagle-size-48'></div>
                                <p>48</p>
                            </div>
                            <div className='rectangle-56'>
                                <div className='rectnagle-size-56'></div>
                                <p>56</p>
                            </div>
                            <div className='rectangle-64'>
                                <div className='rectnagle-size-64'></div>
                                <p>64</p>
                            </div>
                        </div>
                        <div className='rectangle-32px'></div>
                        <p className='opensnas-reg-16px'>A better way to work</p>
                        <div className='rectangle-16px'></div>
                        <p className='notoserif-bold-28px'>A better way to work</p>
                        <p className='notoserif-bold-28px'>A better way to work</p>
                        <div className='rectangle-16px'></div>
                        <p className='opensans-reg-14px'>For indentation or spacing definition 8px is a good basic unit to
                            work with. The number 8 is easily multiplied and provides flexible
                            and consistent, yer distinct enough, steps between. Most measurements
                            align to an 8px grid applied, which aligns both spacing and the
                            overall layout.</p>
                        <div className='rectangle-32px'></div>
                        <div className='button-apply'>Apply</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Spacing