import React, { useContext, useEffect, useRef } from "react";
import NavBar from "../NavBar/NavBar";
import "./Thelogo.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Thelogo = ({setLogoHeight, logoActive}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };
  
 
  return (
    <section
      className="thelogo section-height"
      id="thelogo"
      onClick={() => {
        handleClick();
      }}
    >
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
      </style>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
      </style>

      <div className="thelogobackground">
        <div className="contentcontainer">
          <h1>Logo</h1>
          <div className="container-for-1-2">
            <div className="contentcontainer-1">
              <p>The Fincity is composed of simple geometric shapes.</p>
              <p>
                The symbol is legible at most sizes, and a small version is
                provided for micro applications.
              </p>
              <p>
                The two basic shapes used in the octothorpe—a speech bubble and
                a lozenge—can be extracted and used as graphic elements.
              </p>
            </div>
            <div className="contentcontainer-2">
              <img
                className="logogroup"
                src={require("../Assets/ThelogoGroup.png")}
              ></img>
              <div className="container-2-grid">
                <h2>19x19 Grid</h2>
                <img
                  className="logogrid"
                  src={require("../Assets/thelogogrid.png")}
                ></img>
              </div>
            </div>
          </div>
          <h1>Sizing</h1>
          <div className="container-for-3-4">
            <div className="contentcontainer-3">
              <p>
                There is a specific logo for small sizes and should be used when
                the logo is rendered at a size between 50px wide and 90px wide.
              </p>
              <p>
                The small logo mark should be used at a size between 15px tall
                and 20px tall.
              </p>
              <p>
                Only the horizontal logos are appropriate for small use. Do not
                use the Fincity logo for small use.
              </p>
            </div>
            <div className="contentcontainer-4">
              <div className="max-sizing">
                <div className="firstsizing">
                  <h2 className="first-text">
                    Maximum Sizing for Small use Logo
                  </h2>
                </div>
                <div className="secondsizing">
                  <h6>90px wide, approx.</h6>
                  <img
                    src={require("../Assets/Group46561.png")}
                    width="100%"
                    height="auto"
                    style={{ maxWidth: "90px" }}
                  ></img>
                  <h6>(Or approx. 1.25 inches)</h6>
                </div>
                <div className="thirdsizing">
                  <h6>20px height, approx.</h6>
                  <img
                    src={require("../Assets/Group 46562.png")}
                    width="20px"
                    height="15px"
                  ></img>
                  <h6>(Or approx. 0.28 inches)</h6>
                </div>
              </div>
              <div className="min-sizing">
                <div className="firstsizing">
                  <h2 className="first-text">
                    Minimum Sizing for Small use Logo
                  </h2>
                </div>
                <div className="secondsizing">
                  <h6>50px wide, approx.</h6>
                  <img
                    src={require("../Assets/Group46561.png")}
                    width="100%"
                    height="auto"
                    style={{ maxWidth: "90px" }}
                  ></img>
                  <h6>(Or approx. 0.7 inches)</h6>
                </div>
                <div className="thirdsizing">
                  <h6>15px wide, approx.</h6>
                  <img
                    src={require("../Assets/Group 46563.png")}
                    width="20px"
                    height="15px"
                  ></img>
                  <h6>(Or approx. 0.7 inches)</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Thelogo;
