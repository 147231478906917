import React, { useContext, useEffect } from "react";
import "./DateTimePicker.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const DateTimePicker = ({setDatetimeHeight, datetimeActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="DateTimePicker section-height"
      id="DateTimePicker"
      ref={ref}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="DateTimePicker-background">
        <div className="DateTimePicker-elem">
          <div className="date-picker">
            <h4>Date Picker</h4>
            <p>
              Date pickers let users select a date or range of dates. They
              should be suitable for the context in which they appear.
            </p>
            <img
              className="datepicker-img"
              src={require("../Assets/Datetimepicker/datepicker.png")}
            ></img>
          </div>
          <h5>Specs</h5>
          <div className="date-specs">
            <img
              className="date-spec-1"
              src={require("../Assets/Datetimepicker/date-spec-1.png")}
            ></img>
            <img
              className="date-spec-2"
              src={require("../Assets/Datetimepicker/date-spec-2.png")}
            ></img>
            <img
              className="date-spec-3"
              src={require("../Assets/Datetimepicker/date-spec-3.png")}
            ></img>
          </div>
          <div className="time-picker">
            <h4>Time Picker</h4>
            <p>
              There are two primary methods for selecting time with the mobile
              time picker. Users can:{" "}
            </p>
            <p>
              Type in a specific value in the hour and minute fields. Select the
              hour or minute field from the text input and adjust the clock dial
              below to simultaneously change the corresponding time field above.
            </p>
          </div>
          <h5>Specs</h5>
          <div className="time-specs">
            <img
              className="time-spec-1"
              src={require("../Assets/Datetimepicker/time-spec-1.png")}
            ></img>
            <div className="time-specs-2-3">
              <img
                className="time-spec-2"
                src={require("../Assets/Datetimepicker/time-spec-2.png")}
              ></img>
              <img
                className="time-spec-3"
                src={require("../Assets/Datetimepicker/time-spec-3.png")}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DateTimePicker;
