import React, { useContext, useEffect } from "react";
import "./TextFieldDropdown.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const TextFieldDropdown = ({setTextfieldHeight, textfieldActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      ref={ref}
      className="TextFieldDropdown section-height"
      id="TextFieldDropdown"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="TextFieldDropdown-background">
        <div className="TextFieldDropdown-elem">
          <div className="text-field">
            <h4>Text Field</h4>
            <div className="text-field-content">
              <p>
                1. Text fields should stand out and indicate that users can
                input information.
              </p>
              <p>
                2. Text field states should be clearly differentiated from one
                another.
              </p>
              <p>
                3. Text fields should make it easy to understand the requested
                information and to address any errors.
              </p>
            </div>
            <div className="text-field-label-img-1">
              <img src={require("../Assets/textfield/label-1.png")}></img>
              <img src={require("../Assets/textfield/label-2.png")}></img>
              <img src={require("../Assets/textfield/label-3.png")}></img>
              <img src={require("../Assets/textfield/label-4.png")}></img>
            </div>
            <div className="text-field-label-img-2">
              <div className="text-field-label-img-row1">
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46589.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img" style={{ marginTop: "5px" }}>
                  <img
                    src={require("../Assets/textfield/Group 46590.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46591.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46592.png")}
                  ></img>
                  Supporting Text
                </div>
              </div>
              <div className="text-field-label-img-row2">
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46593.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46594.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46595.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46593.png")}
                  ></img>
                  Supporting Text
                </div>
              </div>
              <div className="text-field-label-img-row3">
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46597.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img" style={{ marginTop: "5px" }}>
                  <img
                    src={require("../Assets/textfield/Group 46598.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46599.png")}
                  ></img>
                  Supporting Text
                </div>
              </div>
              <div className="text-field-label-img-row4">
                <div className="label-text-img">
                  <img
                    src={require("../Assets/textfield/Group 46600.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img" style={{ marginTop: "5px" }}>
                  <img
                    src={require("../Assets/textfield/Group 46601.png")}
                  ></img>
                  Supporting Text
                </div>
                <div className="label-text-img" style={{ marginTop: "5px" }}>
                  <img
                    src={require("../Assets/textfield/Group 46602.png")}
                  ></img>
                  Supporting Text
                </div>
              </div>
            </div>
          </div>
          <div className="drop-field">
            <h4>Dropdown</h4>
            <div className="drop-field-img">
              <img src={require("../Assets/textfield/Group 46603.png")}></img>
              <img src={require("../Assets/textfield/Group 46604.png")}></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextFieldDropdown;
