import React, { useContext, useEffect } from "react";
import "./Checkboxes.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Checkboxes = ({setCheckboxHeight, checkboxActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Checkboxes section-height"
      ref={ref}
      id="Checkboxes"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Checkboxes-background">
        <div className="checkboxes-elem">
          <div className="checkboxes-first">
            <h4>Checkboxes</h4>
            <h5>Parent and child checkboxes</h5>
            <p>
              Checkboxes can have a parent-child relationship with other
              checkboxes. <br /> <br />
              1. When the parent checkbox is checked, all child checkboxes are
              checked <br />
              2. If a parent checkbox is unchecked, all child checkboxes are
              unchecked <br />
              3. If some, but not all, child checkboxes are checked, the parent
              checkbox becomes an indeterminate checkbox <br />
            </p>
          </div>
          <div className="checkboxes-second">
            <div className="checkboxes-selected">
              <h4>Selected</h4>
              <div className="selected-img">
                <div className="img-text">
                  <div className="align-img-helper">
                    <img
                      src={require("../Assets/checkboxes/selected/enabled.png")}
                      width="25px"
                      height="25px"
                    ></img>
                  </div>
                  <p>Enabled</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/selected/group46574.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Disabled</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/selected/group46574.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Hover</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/selected/group46574.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Focused</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/selected/group46574.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Pressed</p>
                </div>
              </div>
            </div>
            <div className="checkboxes-unselected">
              <h4>UnSelected</h4>
              <div className="selected-img">
                <div className="img-text">
                  <div className="align-img-helper">
                    <img
                      src={require("../Assets/checkboxes/unselected/enabled.png")}
                      width="25px"
                      height="25px"
                    ></img>
                  </div>
                  <p>Enabled</p>
                </div>
                <div className="img-text">
                  <div className="align-img-helper">
                    <img
                      src={require("../Assets/checkboxes/unselected/enabled.png")}
                      width="25px"
                      height="25px"
                    ></img>
                  </div>
                  <p>Disabled</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/unselected/focused.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Hover</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/unselected/hover.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Focused</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/unselected/pressed.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Pressed</p>
                </div>
              </div>
            </div>
            <div className="checkboxes-intermediate">
              <h4>Intermediate</h4>
              <div className="selected-img">
                <div className="img-text">
                  <div className="align-img-helper">
                    <img
                      src={require("../Assets/checkboxes/intermediate/enabled.png")}
                      width="25px"
                      height="25px"
                    ></img>
                  </div>
                  <p>Enabled</p>
                </div>
                <div className="img-text">
                  <div className="align-img-helper">
                    <img
                      src={require("../Assets/checkboxes/intermediate/disabled.png")}
                      width="25px"
                      height="25px"
                    ></img>
                  </div>
                  <p>Disabled</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/intermediate/group46266.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Hover</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/intermediate/group46266.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Focused</p>
                </div>
                <div className="img-text">
                  <img
                    src={require("../Assets/checkboxes/intermediate/group46266.png")}
                    width="40px"
                    height="40px"
                  ></img>
                  <p>Pressed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkboxes;
