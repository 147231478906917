import React, { useContext, useEffect } from "react";
import "./FloatingActionButton.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const FloatingActionButton = ({setFloatingHeight, floatingActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="FloatingActionButton section-height"
      id="FloatingActionButton"
      onClick={() => {
        handleClick();
      }}
      ref={ref}
    >
      <div className="floatingactionbutton-background">
        <h1>Floating Action Button (FAB)</h1>
        <div className="floatingactionbutton-elem">
          <div className="floatingactionbutton-container-1">
            <p>
              A floating action button (FAB) performs the primary, or most
              common, action on a screen. It appears in front of all screen
              content, typically as a circular shape with an icon in its center.
              FABs come in three types: regular, mini, and extended.{" "}
            </p>
            <p>
              Only use a FAB if it is the most suitable way to present a
              screen’s primary action.
            </p>
          </div>
          <div className="floatingactionbutton-container-2">
            <div className="container-2-middle">
              <div className="fab-text">
                <p>FAB containers come in two sizes: </p>
                <p>1. Default (56 x 56dp) </p>
                <p> 2. Mini (40 x 40dp)</p>
              </div>
              <div className="fab-img">
                <img
                  className="img-1"
                  src={require("../Assets/FAB/plus_circle_outline.png")}
                  width="18px"
                  height="18px"
                ></img>
                <img
                  className="img-2"
                  src={require("../Assets/FAB/Ellipse1.png")}
                  width="56px"
                  height="56px"
                ></img>
                <img
                  src={require("../Assets/FAB/Group46259.png")}
                  width="40px"
                  height="40px"
                ></img>
              </div>
            </div>
          </div>
          <div className="floatingactionbutton-container-3">
            <div className="first">
              <h5 style={{ fontWeight: "bold", fontSize: "18px" }}>Actions</h5>
              <p style={{ fontWeight: "normal", fontSize: "16px" }}>
                A floating action button (FAB) can trigger an action either on
                the current screen, or it can perform an action that creates a
                new screen.
                <br />
                <br />A floating action button (FAB) can trigger an action
                either on the current screen, or it can perform an action that
                creates a new screen.{" "}
              </p>
            </div>
            <div className="first">
              <h5 style={{ fontWeight: "600", fontSize: "16px" }}>
                A FAB promotes an important, constructive action such as:
              </h5>
              <p>
                1. Create <br />
                2. Favorite <br />
                3. Share <br />
                4. Start a process <br />
              </p>
            </div>
            <div className="first">
              <h5 style={{ fontWeight: "600", fontSize: "16px" }}>
                Avoid using a FAB for minor or destructive actions, such as:
              </h5>
              <p>
                1. Archive or trash
                <br />
                2. Alerts or errors <br />
                3. Limited tasks like cutting text <br />
                4. Controls better suited to a toolbar (like controls to adjust
                volume or font color)
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FloatingActionButton;
