import React from 'react'
import './NavDropdown.css'

const ComponentNavDropdown = ({ onClickHandler }) => {
  return (
    <nav>
      <div className="navDropdown" id="mainNav">
        <ul>
          <li
            className="Buttons Buttons-Id active"
            onClick={(e) => onClickHandler(e, "Buttons")}
          >
            <a href="#Buttons" className="navDropdown-button">
              Buttons
            </a>
          </li>
          <li
            className="FloatingActionButton floatingsnavlink"
            onClick={(e) => onClickHandler(e, "FloatingActionButton")}
          >
            <a href="#FloatingActionButton" className="navDropdown-button">
              Floating Action Button
            </a>
          </li>
          <li
            className="RadioToggleButton radionavlink"
            onClick={(e) => onClickHandler(e, "RadioToggleButton")}
          >
            <a href="#RadioToggleButton" className="navDropdown-button">
              Radio Toggle Button
            </a>
          </li>
          <li
            className="Checkboxes checkboxesnavlink"
            onClick={(e) => onClickHandler(e, "Checkboxes")}
          >
            <a href="#Checkboxes" className="navDropdown-button">
              Checkboxes
            </a>
          </li>
          <li
            className="Chips chipsnavlink"
            onClick={(e) => onClickHandler(e, "Chips")}
          >
            <a href="#Chips" className="navDropdown-button">
              Chips
            </a>
          </li>
          <li
            className="Stepper steppernavlink"
            onClick={(e) => onClickHandler(e, "Stepper")}
          >
            <a href="#Stepper" className="navDropdown-button">
              Stepper
            </a>
          </li>
          <li
            className="Tabs tabsnavlink"
            onClick={(e) => onClickHandler(e, "Tabs")}
          >
            <a href="#Tabs" className="navDropdown-button">
              Tabs
            </a>
          </li>
          <li
            className="Snackbars snackbarsnavlink"
            onClick={(e) => onClickHandler(e, "Snackbars")}
          >
            <a href="#Snackbars" className="navDropdown-button">
              Snackbars
            </a>
          </li>
          <li
            className="Dialogbox dialogboxnavlink"
            onClick={(e) => onClickHandler(e, "Dialogbox")}
          >
            <a href="#Dialogbox" className="navDropdown-button">
              Dialogbox
            </a>
          </li>
          <li
            className="BottomSheet bottomsheetnavlink"
            onClick={(e) => onClickHandler(e, "BottomSheet")}
          >
            <a href="#BottomSheet" className="navDropdown-button">
              Bottom Sheet
            </a>
          </li>
          <li
            className="Cards cardsnavlink"
            onClick={(e) => onClickHandler(e, "Cards")}
          >
            <a href="#Cards" className="navDropdown-button">
              Cards
            </a>
          </li>
          <li
            className="DateTimePicker datetimenavlink"
            onClick={(e) => onClickHandler(e, "DateTimePicker")}
          >
            <a href="#DateTimePicker" className="navDropdown-button">
              Date Time Picker
            </a>
          </li>
          <li
            className="TextFieldDropdown textfieldnavlink"
            onClick={(e) => onClickHandler(e, "TextFieldDropdown")}
          >
            <a href="#TextFieldDropdown" className="navDropdown-button">
              Text Field Dropdown
            </a>
          </li>
          <li
            className="DataTable datatablenavlink"
            onClick={(e) => onClickHandler(e, "DataTable")}
          >
            <a href="#DataTable" className="navDropdown-button">
              Data Table
            </a>
          </li>
          <li
            className="phoneNumberBackground phonenumnavlink"
            onClick={(e) => onClickHandler(e, "phoneNumberBackground")}
          >
            <a href="#phoneNumberBackground" className="navDropdown-button">
              Phone Number
            </a>
          </li>
          <li
            className="FAQ faqnavlink"
            onClick={(e) => onClickHandler(e, "FAQ")}
          >
            <a href="#FAQ" className="navDropdown-button">
              FAQ
            </a>
          </li>
          <li
            className="Others othersnavlink"
            onClick={(e) => onClickHandler(e, "Others")}
          >
            <a href="#Others" className="navDropdown-button">
              Others
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default ComponentNavDropdown