import React, { useContext }  from 'react'
import './ColorPallete.css'
import { AppContext } from '../../context/AppContext'

const ColorPallete = () => {
  const context = useContext(AppContext);
  const handleClick = ()=>{
      context?.setAppData({
          ...context.appData,
          toggleNavbar: false
        });

      };
  return (
    <div className='ColorPallete' onClick={()=>{handleClick()}}>
      <div className='ColorPallete-background'>
        <h1>Color Pallete</h1>
        <hr className='titlebottomborder'></hr>
        <div className='colorpallete-elem'>
          <div className='colorpallete-pri'>
            <p>Primary Color</p>
            <div className='primary-color-container'>
              <div className='primary-color-text'>
                <div className='primary-square' style={{ backgroundColor: '#1F3C3D' }}></div>
                <p>#1F3C3D</p>
              </div>
              <div className='primary-color-text'>
                <div className='primary-square' style={{ backgroundColor: '#E5B122' }}></div>
                <p>#E5B122</p>
              </div>
              <div className='primary-color-text'>
                <div className='primary-square' style={{ backgroundColor: '#08705C' }}></div>
                <p>#08705C</p>
              </div>
            </div>
          </div>
          <div className='colorpallete-sec'>
            <p>Secondary Color</p>
            <div className='secondary-color-container-1'>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#CBCFF4' }}></div>
                <p>#CBCFF4</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#F9E4C0' }}></div>
                <p>#F9E4C0</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#EAD0BE' }}></div>
                <p>#EAD0BE</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#D2E7F7' }}></div>
                <p>#D2E7F7</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#BA9F9B' }}></div>
                <p>#BA9F9B</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#E7E3DD' }}></div>
                <p>#E7E3DD</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#DEE2D0' }}></div>
                <p>#DEE2D0</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#E9EBE1' }}></div>
                <p>#E9EBE1</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#DBD8AB' }}></div>
                <p>#DBD8AB</p>
              </div>
            </div>
            <div className='secondary-color-container-2'>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#2680EB' }}></div>
                <p>#2680EB</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#393A3C' }}></div>
                <p>#393A3C</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#6C7586' }}></div>
                <p>#6C7586</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#8E90A4' }}></div>
                <p>#8E90A4</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#C7C8D6' }}></div>
                <p>#C7C8D6</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#DFE4E8' }}></div>
                <p>#DFE4E8</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#EBEAEE' }}></div>
                <p>#EBEAEE</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#F3F1F6' }}></div>
                <p>#F3F1F6</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#F9F9F9' }}></div>
                <p>#F9F9F9</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#FFFFFF' }}></div>
                <p>#FFFFFF</p>
              </div>
            </div>
          </div>
          <div className='colorpallete-sec'>
            <p>Secondary Color</p>
            <div className='secondary-color-container-1'>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#EC6B5F' }}></div>
                <p>#EC6B5F</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#F3BF4E' }}></div>
                <p>#F3BF4E</p>
              </div>
              <div className='secondary-color-text'>
                <div className='secondary-square' style={{ backgroundColor: '#61C554' }}></div>
                <p>#61C554</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColorPallete
