import React, { useContext, useEffect } from "react";
import "./Snackbars.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Snackbars = ({setSnackbarHeight, snackbarActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Snackbars section-height"
      id="Snackbars"
      onClick={() => {
        handleClick();
      }}
      ref={ref}
    >
      <div className="Snackbars-background">
        <h1>Snackbars</h1>
        <div className="Snackbars-elem">
          <div className="Snackbars-text">
            <p>
              Snackbars inform users of a process that an app has performed or
              will perform. They appear temporarily, towards the bottom of the
              screen. They shouldn’t interrupt the user experience, and they
              don’t require user input to disappear.
            </p>
            <h4>
              A snackbar can contain a single action. “Dismiss” or “cancel”
              actions are optional.
            </h4>
          </div>
          <img
            className="snackbar-main"
            src={require("../Assets/Snackbars/snackbar-main.png")}
          ></img>
          <div className="snackbar-img">
            <img
              className="snackbar-1"
              src={require("../Assets/Snackbars/snackbar-1.png")}
            ></img>
            <img
              className="snackbar-2"
              src={require("../Assets/Snackbars/snackbar-2.png")}
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Snackbars;
