import React, { useContext }  from "react";
import "./Iconography.css";
import { AppContext } from '../../context/AppContext'



function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => {
    images.push(r(item));
  });
  return images;
}
const images = importAll(
  require.context('../Assets/iconography', false, /\.(png|jpe?g|svg)$/)
);

function importAll_2(r) {
  let filled_images = [];
  r.keys().forEach((item, index) => {
    filled_images.push(r(item));
  });
  return filled_images;
}
const filled_images = importAll_2(
  require.context('../Assets/iconography/filled-icons', false, /\.(png|jpe?g|svg)$/)
);

function importAll_3(r) {
  let char_images = [];
  r.keys().forEach((item, index) => {
    char_images.push(r(item));
  });
  return char_images;
}
const char_images = importAll_3(
  require.context('../Assets/iconography/character-icons', false, /\.(png|jpe?g|svg)$/)
);


const Iconography = () => {
  const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };
  return (
    <div className="Iconography" onClick={()=>{handleClick()}}>
      <div className="Iconography-background">
        <div className="Iconography-title">
          <h1>Iconography</h1>
          <hr className="titlebottomborder"></hr>
        </div>
        <img className="icon-size" src={require('../Assets/iconography/line-icons/icon-size.png')}></img>
        <div className="Iconography-elem">
          <p>Line Icon</p>
          <div className="line-icon">
            {images.map(each => (
                <img src={each}></img>
            ))}
          </div>
          <p>Filled Icon</p>
          <div className="filled-icon">
          {filled_images.map(each => (
                <img src={each}></img>
            ))}
          </div>
          <p>Status</p>
          <div className="status-icon">
            <div className="status open">
              <img src={require('../Assets/iconography/status/basic - external_link.png')} width='22px' height='22px'></img>
              Open
            </div>
            <div className="status lead">
              <img src={require('../Assets/iconography/status/noun-star-5079394.png')} width='22px' height='22px'></img>
              Lead
            </div>
            <div className="status meetingdone">
              <img src={require('../Assets/iconography/status/noun-meeting-293984.png')} width='22px' height='22px'></img>
              Meeting Done
            </div>
            <div className="status visitdone">
              <img src={require('../Assets/iconography/status/noun-visited-3859386.png')} width='22px' height='22px'></img>
              Visit Done
            </div>
            <div className="status revisitdone">
              <img src={require('../Assets/iconography/status/noun-revisit-4699745.png')} width='22px' height='22px'></img>
              Re-Visit Done
            </div>
            <div className="status bookingdone">
              <img src={require('../Assets/iconography/status/noun-booking-4745254.png')} width='22px' height='22px'></img>
              Booking Done
            </div>
            <div className="status cancelled">
              <img src={require('../Assets/iconography/status/Group_42100.png')} width='22px' height='22px'></img>
              Cancelled
            </div>
            <div className="status invited">
              <img src={require('../Assets/iconography/status/Group_42009.png')} width='22px' height='22px'></img>
              Invited
            </div>
            <div className="status approval-pending">
              <img src={require('../Assets/iconography/status/Group_43179.png')} width='22px' height='22px'></img>
              Approval Pending
            </div>
            <div className="status rejected">
              <img src={require('../Assets/iconography/status/Group_42100.png')} width='22px' height='22px'></img>
              Rejected
            </div>
            <div className="status self-registered">
              <img src={require('../Assets/iconography/status/Group_43184.png')} width='22px' height='22px'></img>
              Self-Registered
            </div>
            <div className="status app-downloaded">
              <img src={require('../Assets/iconography/status/Group_43186.png')} width='22px' height='22px'></img>
              App Downloaded
            </div>
            <div className="status rejected">
              <img src={require('../Assets/iconography/status/noun-tick-801900.png')} width='22px' height='22px'></img>
              Rejected
            </div>

          </div>
          <p>Character Icon</p>
          <div className="channel-icon">
          {char_images.map(each => (
                <img src={each}></img>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iconography;
