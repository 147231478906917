import React, { useContext, useEffect } from "react";
import "./BottomSheet.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const BottomSheet = ({setBottomsheetHeight, bottomsheetActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="BottomSheet section-height"
      id="BottomSheet"
      onClick={() => {
        handleClick();
      }}
      ref={ref}
    >
      <div className="BottomSheet-background">
        <h1>BottomSheet</h1>
        <div className="BottomSheet-elem">
          <p>
            Bottom sheets are supplementary surfaces primarily used on mobile.
          </p>
          <div className="BottomSheet-img">
            <img
              className="BottomSheet-first"
              src={require("../Assets/Bottomsheet/first.png")}
              width="368px"
              height="337px"
            ></img>
            <img
              className="BottomSheet-second"
              src={require("../Assets/Bottomsheet/second.png")}
              width="478px"
              height="352px"
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomSheet;
