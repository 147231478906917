import React, { useContext, useEffect } from "react";
import "./Stepper.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Stepper = ({setStepperHeight, stepperActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };


  return (
    <section
      className="Stepper section-height"
      ref={ref}
      id="Stepper"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Stepper-background">
        <h1>Stepper</h1>
        <div className="Stepper-elem">
          <div className="Stepper-text">
            <h4 className="blue">For Web</h4>
            <p>
              Steppers display progress through a sequence of logical and
              numbered steps. They may also be used for navigation. Steppers may
              display a transient feedback message after a step is saved.
            </p>
          </div>
          <div className="Stepper-img">
            <img
              className="stepper-1"
              src={require("../Assets/stepper/stepper-1.png")}
            ></img>
            <img
              className="stepper-2"
              src={require("../Assets/stepper/stepper-2.png")}
            ></img>
          </div>
          <img
            className="alert"
            src={require("../Assets/stepper/alert.png")}
          ></img>
          <img
            className="types-step"
            src={require("../Assets/stepper/types-step.png")}
          ></img>
          <h4 className="blue">For Mobile</h4>
          <img
            className="mobile"
            src={require("../Assets/stepper/mobile.png")}
          ></img>
        </div>
      </div>
    </section>
  );
};

export default Stepper;
