import React, { useContext, useEffect } from "react";
import "./RadioToggleButton.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const RadioToggleButton = ({setRadioHeight, radioActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="RadioToggleButton section-height"
      id="RadioToggleButton"
      ref={ref}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="RadioToggleButton-background">
        <div className="RadioToggleButton-elem">
          <div className="RadioToggleButton-container">
            <div className="first">
              <h5>Radio Button</h5>
              <p>
                Radio buttons are a selection control that often appears when
                users are asked to make decisions or select a choice from
                options.
              </p>
            </div>
            <div className="second">
              <div className="selected">
                <h4>Selected</h4>
                <div className="selected-img">
                  <div className="img-text">
                    <div className="radio-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/enabled.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Enabled</p>
                  </div>
                  <div className="img-text">
                    <div className="radio-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/disabled.png")}
                        width="24px"
                        height="24px"
                      ></img>
                    </div>
                    <p>Disabled</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/hover.png")}
                      width="40px"
                      height="40px"
                    ></img>
                    <p>Hover</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/hover.png")}
                      width="40px"
                      height="40px"
                    ></img>
                    <p>Focused</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/hover.png")}
                      width="40px"
                      height="40px"
                    ></img>
                    <p>Pressed</p>
                  </div>
                </div>
              </div>
              <div className="unselected">
                <h4>UnSelected</h4>
                <div className="unselected-img">
                  <div className="img-text">
                    <div className="circle-enabled radio-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/grey/ring.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Enabled</p>
                  </div>
                  <div className="img-text">
                    <div className="circle-disabled radio-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/grey/ring-light.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Disabled</p>
                  </div>
                  <div className="img-text">
                    <div className="circle-hover">
                      <img
                        src={require("../Assets/radiotogglebutton/grey/ring.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Hover</p>
                  </div>
                  <div className="img-text">
                    <div className="circle-focused">
                      <img
                        src={require("../Assets/radiotogglebutton/grey/ring.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Focused</p>
                  </div>
                  <div className="img-text">
                    <div className="circle-pressed">
                      <img
                        src={require("../Assets/radiotogglebutton/grey/ring.png")}
                        width="20px"
                        height="20px"
                      ></img>
                    </div>
                    <p>Pressed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="RadioToggleButton-container">
            <div className="first">
              <h5>Toggle Button</h5>
              <p>
                Use this selection control when the user needs to toggle a
                single item on or off.
              </p>
            </div>
            <div className="second">
              <div className="selected">
                <h4>Selected</h4>
                <div className="selected-img">
                  <div className="img-text">
                    <div className="toggle-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/togglegold/Group 46269.png")}
                        width="53px"
                        height="32px"
                      ></img>
                    </div>
                    <p>Enabled</p>
                  </div>
                  <div className="img-text">
                    <div className="toggle-button-img-helper">
                      <img
                        src={require("../Assets/radiotogglebutton/togglegold/Group 46270.png")}
                        width="53px"
                        height="32px"
                      ></img>
                    </div>
                    <p>Disabled</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/togglegold/Group 46569.png")}
                      width="58px"
                      height="40px"
                    ></img>
                    <p>Hover</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/togglegold/Group 46569.png")}
                      width="58px"
                      height="40px"
                    ></img>
                    <p>Focused</p>
                  </div>
                  <div className="img-text">
                    <img
                      src={require("../Assets/radiotogglebutton/togglegold/Group 46569.png")}
                      width="58px"
                      height="40px"
                    ></img>
                    <p>Pressed</p>
                  </div>
                </div>
              </div>
              <div className="unselected">
                <h4>UnSelected</h4>
                <div className="unselected-img">
                  <div className="img-text">
                    <div className="toggle-button-img-helper-2">
                      <img
                        src={require("../Assets/radiotogglebutton/togglegrey/Group 41001.png")}
                        width="53px"
                        height="32px"
                      ></img>
                    </div>
                    <p>Enabled</p>
                  </div>
                  <div className="img-text">
                    <div className="toggle-button-img-helper-2">
                      <img
                        src={require("../Assets/radiotogglebutton/togglegrey/Group 46274.png")}
                        width="53px"
                        height="32px"
                      ></img>
                    </div>
                    <p>Disabled</p>
                  </div>
                  <div className="img-text">
                    <div>
                      <img
                        src={require("../Assets/radiotogglebutton/togglegrey/Group 46573.png")}
                        width="58px"
                        height="40px"
                      ></img>
                    </div>
                    <p>Hover</p>
                  </div>
                  <div className="img-text">
                    <div>
                      <img
                        src={require("../Assets/radiotogglebutton/togglegrey/Group 46573.png")}
                        width="58px"
                        height="40px"
                      ></img>
                    </div>
                    <p>Focused</p>
                  </div>
                  <div className="img-text">
                    <div>
                      <img
                        src={require("../Assets/radiotogglebutton/togglegrey/Group 46573.png")}
                        width="58px"
                        height="40px"
                      ></img>
                    </div>
                    <p>Pressed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RadioToggleButton;
