import React, { useContext, useEffect } from "react";
import "./Dialogbox.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Dialogbox = ({setDialogboxHeight, dialogboxActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Dialogbox section-height"
      ref={ref}
      id="Dialogbox"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Dialogbox-background">
        <h1>Dialogbox</h1>
        <div className="Dialogbox-elem">
          <div className="Dialogbox-first">
            <p className="Dialogbox-text">
              Dialogs can require an action, communicate information, or help
              users accomplish a task. There are two types of dialogs:
            </p>
            <p style={{ display: "inline" }}>Basic and Full-screen.</p>
            <img
              className="basic-full"
              src={require("../Assets/Dialog box/basic-full.png")}
            ></img>
          </div>
          <div className="Dialogbox-second">
            <p>Full-screen dialogs</p>
            <img
              className="full"
              src={require("../Assets/Dialog box/full.png")}
            ></img>
          </div>
          <div className="Dialogbox-third">
            <p>Basic Dialog title</p>
            <img
              className="basic"
              src={require("../Assets/Dialog box/basic.png")}
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dialogbox;
