import React, { useEffect, useRef, useState } from "react";
import Thelogo from "../Thelogo/Thelogo";
import UsesOnBackground from "../UsesOnBackground/UsesOnBackground";
import LogoMisuse from "../LogoMisuse/LogoMisuse";
import "./LogoDropdown.css";
import LogoNavDropdown from "../NavDropdown/LogoNavDropdown";
import dimensions from "../../dimension/dimensions";

const LogoDropdown = () => {
  const [navMenu, navMenuHeight] = dimensions("StickyNav");

  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll("nav div.navDropdown ul li");
  const [runEvent, setRunEvent] = useState(false);

  function onClickHandler(e, id) {
    e.preventDefault();
    setRunEvent(true);
    const elements = document.getElementsByClassName(id);
    elements[1].scrollIntoView({ block: "start" });
    console.log(elements, "element");
    navLi.forEach((li) => li.classList.remove("active"));
    elements[0].classList.add("active");
    setTimeout(() => setRunEvent(false), 1500);
  }

  window.onscroll = () => {
    if (!runEvent) {
      var current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop - navMenuHeight) {
          current = section.getAttribute("id");
        }
      });
      navLi.forEach((li) => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
          li.classList.add("active");
          li.scrollIntoView({ inline: "center" });
        }
      });
    }
  };

  return (
    <div className="thefincitylogobackground thelogobackground">
      <div className="justTry">
        <div className="StickyNav" id="StickyNav">
          <h1>The Fincity Logo</h1>
          <hr className="titlebottomborder"></hr>
          <LogoNavDropdown onClickHandler={onClickHandler}></LogoNavDropdown>
        </div>
        <div
          className="scrollcomponent"
          id="scrollcomponent"
          style={{ marginTop: navMenuHeight }}
        >
          <Thelogo></Thelogo>
          <UsesOnBackground></UsesOnBackground>
          <LogoMisuse></LogoMisuse>
        </div>
      </div>
    </div>
  );
};

export default LogoDropdown;
