import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "./FAQ.css";

const FAQ = () => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="FAQ faq-elems"
      id="FAQ"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="faq-desktop">
        <h1>FAQ’s (Frequently Asked Questions)</h1>
        <div className="faq-img">
          <img src={require("../Assets/FAQ/faq.png")} width="900px"></img>
        </div>
      </div>
      <div className="faq-mobile">
        <h1>Mobile view</h1>
        <img src={require("../Assets/FAQ/mobile.png")} width="330px"></img>
      </div>
    </section>
  );
};
export default FAQ;
