import React, { useContext, useEffect } from "react";
import "./Cards.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Cards = ({setCardsHeight, cardsActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Cards section-height"
      id="Cards"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Cards-background">
        <h1>Cards</h1>
        <div className="Cards-elem">
          <div className="Cards-text">
            <p>
              Cards are surfaces that display content and actions on a single
              topic.
            </p>
            <p>
              They should be easy to scan for relevant and actionable
              information. Elements, like text and images, should be placed on
              them in a way that clearly indicates hierarchy.
            </p>
          </div>
          <div className="Cards-img">
            <img
              className="Cards-img-first"
              src={require("../Assets/Cards/first.png")}
            ></img>
            <img
              className="Cards-img-second"
              src={require("../Assets/Cards/second.png")}
            ></img>
            <img
              className="Cards-img-third"
              src={require("../Assets/Cards/third.png")}
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards;
