import React, { useContext, useEffect } from "react";
import "./Chips.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Chips = ({setChipHeight, chipActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Chips section-height"
      id="Chips"
      ref={ref}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Chips-background">
        <h1>Chips</h1>
        <div className="chips-elem">
          <div className="chips-first">
            <div className="chips-first-text">
              <h4>Chips aren’t buttons</h4>
              <p>
                Chips and buttons are similar in that they both provide visual
                cues to prompt users to take actions and make selections.
                However, there are important distinctions to be aware of.
              </p>
              <p>
                Multiple chips should appear together in a group, whereas there
                should be no more than 3 buttons in a single arrangement.
              </p>
            </div>
            <div className="chips-img">
              <div className="chips-button">
                <img src={require("../Assets/chips/norm-button.png")}></img>
                <img src={require("../Assets/chips/pic-button.png")}></img>
              </div>
              <div className="chips-en-dis">
                <img src={require("../Assets/chips/norm-enabled.png")}></img>
                <img src={require("../Assets/chips/norm-disabled.png")}></img>
                <img src={require("../Assets/chips/pic-enabled.png")}></img>
                <img src={require("../Assets/chips/pic-disabled.png")}></img>
                <img
                  src={require("../Assets/chips/pic-enabled-grey.png")}
                ></img>
                <img
                  src={require("../Assets/chips/pic-disabled-grey.png")}
                ></img>
              </div>
            </div>
          </div>
          <div className="chips-second">
            <img src={require("../Assets/chips/second.png")}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chips;
