import React, { useContext, useEffect } from "react";
import "./Others.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Others = ({setOthersHeight, othersActive, ref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Others section-height"
      id="Others"
      onClick={() => {
        handleClick();
      }}
    >
      <div className="Others-background">
        <div className="others-elem">
          <div className="others-search">
            <h4>Search</h4>
            <p>
              If search is important function then you should be displayed
              prominently, as it can be the fastest route to discovery for
              users.
            </p>
            <p className="blue">
              It’s important to display full open-text field, because search
              hidden behind in icon makes search feature less noticeable and
              increases the cost of interaction:
            </p>
            <img
              className="search"
              src={require("../Assets/others/search.png")}
            ></img>
          </div>
          <div className="others-slider">
            <h4>Slider</h4>
            <p>There are two types of sliders: continuous and discrete.</p>
            <div className="others-slider-img">
              <img
                className="slider-1"
                src={require("../Assets/others/slider-1.png")}
              ></img>
              <img
                className="slider-2"
                src={require("../Assets/others/slider-2.png")}
              ></img>
            </div>
          </div>
          <div className="others-loader">
            <h4>Loader</h4>
            <p>
              Progress indicators commonly known as spinners, express an
              unspecified wait time or display the length of a process.
            </p>
            <img
              className="loading"
              src={require("../Assets/others/loading.png")}
            ></img>
          </div>
          <div className="others-breadcrumbs">
            <h4>Breadcumbs</h4>
            <p>
              Breadcrumbs give you orientation and show you exactly where you
              are on a website.
            </p>
            <div className="others-breadcrumbs-img">
              <p className="blue" style={{ fontWeight: "normal" }}>
                Opportunity
              </p>
              <p> {">"} Client Details</p>
            </div>
          </div>
          <div className="others-links">
            <h4>Links</h4>
            <p>
              The Link component allows you to easily customize anchor elements
              with your theme colors and typography styles.
            </p>
            <div className="others-links-img">
              <p className="blue" style={{ fontWeight: "normal" }}>
                more detailed guide.
              </p>
            </div>
          </div>
          <div className="others-pagination">
            <h4>Pagination</h4>
            <p>
              The Pagination component enables the user to select a specific
              page from a range of pages.
            </p>
            <img
              className="pagination"
              src={require("../Assets/others/pagination.png")}
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Others;
