import React, { useEffect, useRef, useState } from "react";
import "./ComponentsDropdown.css";
import Buttons from "../Buttons/Buttons";
import BottomSheet from "../BottomSheet/BottomSheet";
import Cards from "../Cards/Cards";
import Checkboxes from "../Checkboxes/Checkboxes";
import Chips from "../Chips/Chips";
import Dialogbox from "../Dialogbox/Dialogbox";
import FloatingActionButton from "../FloatingActionButton/FloatingActionButton";
import RadioToggleButton from "../RadioToggleButton/RadioToggleButton";
import TextFieldDropdown from "../TextFieldDropdown/TextFieldDropdown";
import Stepper from "../Stepper/Stepper";
import Tabs from "../Tabs/Tabs";
import Snackbars from "../Snackbars/Snackbars";
import Others from "../Others/Others";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import DataTable from "../DataTable/DataTable";
import ComponentNavDropdown from "../NavDropdown/ComponentNavDropdown";
import dimensions from "../../dimension/dimensions";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import FAQ from "../FAQ/FAQ";

const ComponentsDropdown = () => {
  const [navMenu, navMenuHeight] = dimensions("StickyNav");

  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll("nav div.navDropdown ul li");
  const [runEvent, setRunEvent] = useState(false);

  function onClickHandler(e, id) {
    
    e.preventDefault();
    setRunEvent(true);
    const elements = document.getElementsByClassName(id);
    console.log(elements)
    elements[1].scrollIntoView({ block: "start" });
    console.log(elements, "element");
    navLi.forEach((li) => li.classList.remove("active"));
    elements[0].classList.add("active");
    setTimeout(() => setRunEvent(false), 1500);
  }

  window.onscroll = () => {
    if (!runEvent) {
      var current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop - navMenuHeight) {
          current = section.getAttribute("id");
        }
      });
      navLi.forEach((li) => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
          li.classList.add("active");
          li.scrollIntoView({ inline: "center" });
        }
      });
    }
  };

  return (
    <div className="thecomponentsbackground thelogobackground">
      <div className="StickyNav" id="StickyNav">
        <h1>Components</h1>
        <hr className="titlebottomborder"></hr>
        <ComponentNavDropdown
          onClickHandler={onClickHandler}
        ></ComponentNavDropdown>
      </div>
      <div className="scrollcomponent" style={{ marginTop: navMenuHeight }}>
        <Buttons></Buttons>
        <FloatingActionButton></FloatingActionButton>
        <RadioToggleButton></RadioToggleButton>
        <Checkboxes></Checkboxes>
        <Chips></Chips>
        <Stepper></Stepper>
        <Tabs></Tabs>
        <Snackbars></Snackbars>
        <Dialogbox></Dialogbox>
        <BottomSheet></BottomSheet>
        <Cards></Cards>
        <DateTimePicker></DateTimePicker>
        <TextFieldDropdown></TextFieldDropdown>
        <DataTable></DataTable>
        <PhoneNumber></PhoneNumber>
        <FAQ></FAQ>
        <Others></Others>
      </div>
    </div>
  );
};

export default ComponentsDropdown;
