import React, { useContext, useEffect } from "react";
import "./Buttons.css";
import { IoIosArrowDown } from "react-icons/io";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Buttons = ({setButtonsHeight, PhoneNumberActive, myref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Buttons section-height"
      id="Buttons"
      ref={myref}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="buttons-elems">
        <div className="button-text-1">
          <h4>Buttons</h4>
          <div className="button-text-2">
            <p>
              When choosing the right button for an action, consider the level
              of emphasis each button type provides.
            </p>
            <h6>Hierarchy of buttons</h6>
            <p>1. High Emphasis</p>
            <p>2. Medium Emphasis</p>
            <p>3. Low Emphasis</p>
          </div>
        </div>
        <div className="button-text-img">
          <div className="button-inside-1">
            <div className="button-inside-text-img">
              <h5>1. Elevated button</h5>
              <div className="button-div-elevated">Buttons</div>
            </div>
            <p>
              Elevated buttons are essentially filled tonal buttons with a
              shadow. To prevent shadow creep, only use them when absolutely
              necessary, such as when the button requires visual separation from
              a patterned background.
            </p>
          </div>
          <div className="button-inside-1">
            <div className="button-inside-text-img">
              <h5>2. Filled button</h5>
              <div className="button-div-filled">Buttons</div>
            </div>
            <p>
              Filled buttons have the most visual impact, and should be used for
              important, final actions that complete a flow, like Save, Join
              now, or Confirm.
            </p>
          </div>
          <div className="button-inside-1">
            <div className="button-inside-text-img">
              <h5>3. Outlined button</h5>
              <div className="button-div-outlined">Buttons</div>
            </div>
            <p>
              Outlined buttons are medium-emphasis buttons. They contain actions
              that are important, but aren’t the primary action in an app.
              Outlined buttons pair well with filled buttons to indicate an
              alternative, secondary action.
            </p>
          </div>
          <div className="button-inside-1">
            <div className="button-inside-text-img">
              <h5>4. Text button</h5>
              <div className="button-div-text">Buttons</div>
            </div>
            <p>
              Text buttons are used for the lowest priority actions, especially
              when presenting multiple options. Text buttons can be placed on a
              variety of backgrounds. Until the button is interacted with, its
              container isn’t visible.
            </p>
          </div>
        </div>
        <div className="button-img-container">
          <div className="default">
            <div className="default-text">
              <div className="button-row-1 button-row"></div>
              <img src={require("../Assets/buttons/default.png")}></img>
            </div>
            <div className="button-col-1 button-col">
              <div className="button-row-1 button-row"></div>
              <div className="button-row-2 button-row">
                <p>Enabled</p>
              </div>
              <div className="button-row-3 button-row">
                <p>Disabled</p>
              </div>
              <div className="button-row-4 button-row">
                <p>Hover</p>
              </div>
              <div className="button-row-5 button-row">
                <p>Pressed</p>
              </div>
              <div className="button-row-6 button-row">
                <p>Spinner</p>
              </div>
            </div>
            <div className="button-col-2 button-col">
              <div className="button-row-1 button-row">
                <p>Contained</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/default/enabled-contained.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/default/disabled-contained.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/default/hover-contained.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/default/pressed-contained.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/default/enabled-contained.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-3 button-col">
              <div className="button-row-1 button-row">
                <p>Outlined</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/default/enabled-outlined.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/default/disabled-outlined.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/default/hover-outlined.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/default/pressed-outlined.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/default/spinner-outlined.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-4 button-col">
              <div className="button-row-1 button-row">
                <p>Text</p>
              </div>
              <div className="button-row-2 button-row white">
                <div className="button-div-text default-text-enabled">
                  Buttons
                </div>
              </div>
              <div className="button-row-3 button-row white">
                <div className="button-div-text default-text-disabled">
                  Buttons
                </div>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/default/hover-text.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/default/pressed-text.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/default/spinner-text.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-5 button-col">
              <div className="button-row-1 button-row">
                <p>Split</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/default/split/enabled-split.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/default/split/disabled-split.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/default/split/hover-split.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/default/split/pressed-split.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/default/split/spinner-split.png")}
                ></img>
              </div>
            </div>
          </div>
          <div className="with-icon">
            <div className="default-text">
              <div className="button-row-1 button-row"></div>
              <img src={require("../Assets/buttons/with-icon.png")}></img>
            </div>
            <div className="button-col-1 button-col">
              <div className="button-row-1 button-row"></div>
              <div className="button-row-2 button-row">
                <p>Enabled</p>
              </div>
              <div className="button-row-3 button-row">
                <p>Disabled</p>
              </div>
              <div className="button-row-4 button-row">
                <p>Hover</p>
              </div>
              <div className="button-row-5 button-row">
                <p>Pressed</p>
              </div>
              <div className="button-row-6 button-row">
                <p>Spinner</p>
              </div>
            </div>
            <div className="button-col-2 button-col">
              <div className="button-row-1 button-row">
                <p>Contained</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/contained/enabled-contained.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/contained/disabled-contained.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/contained/hover-contained.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/contained/pressed-contained.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/contained/spinner-contained.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-3 button-col">
              <div className="button-row-1 button-row">
                <p>Outlined</p>
              </div>
              <div className="button-row-2 button-row white">
                <div className="button-div-outlined default-outlined-enabled">
                  Buttons
                </div>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/outlined/disabled-outlined.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/outlined/hover-outlined.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/outlined/pressed-outlined.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/outlined/spinner-outlined.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-4 button-col">
              <div className="button-row-1 button-row">
                <p>Text</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/text/enabled-text.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/text/disabled-text.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/text/hover-text.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/text/pressed-text.png")}
                ></img>
              </div>
              <div className="button-row-6 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/text/spinner-text.png")}
                ></img>
              </div>
            </div>
            <div className="button-col-5 button-col">
              <div className="button-row-1 button-row">
                <p>Split</p>
              </div>
              <div className="button-row-2 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/split/enabled-split.png")}
                ></img>
              </div>
              <div className="button-row-3 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/split/disabled-split.png")}
                ></img>
              </div>
              <div className="button-row-4 button-row white">
                <img
                  src={require("../Assets/buttons/withicon/split/hover-split.png")}
                ></img>
              </div>
              <div className="button-row-5 button-row white">
                <div className="button-div-filled arrow button-pressed">
                  <p>Buttons</p>
                  <div className="buttons-arrow-down">
                    <IoIosArrowDown></IoIosArrowDown>
                  </div>
                </div>
              </div>
              <div className="button-row-6 button-row white">
                <div className="button-div-filled arrow button-spinner button-spinner-arrow">
                  <div className="spinner"></div>
                  <p>Buttons</p>
                  <div className="buttons-arrow-down">
                    <IoIosArrowDown></IoIosArrowDown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Buttons;
