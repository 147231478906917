import React, { useContext, useEffect } from "react";
import "./Tabs.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const Tabs = ({setTabsHeight, tabsHeight, myref}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="Tabs section-height"
      id="Tabs"
      onClick={() => {
        handleClick();
      }}
      ref={myref}
    >
      <div className="Tabs-background">
        <h1>Tabs</h1>
        <div className="Tabs-elem">
          <div className="Tabs-text">
            <p>
              Tabs organize content across different screens, data sets, and
              other interactions.
            </p>
          </div>
          <div className="first-img">
            <img
              className="tabs-top"
              src={require("../Assets/Tabs/Group46582.png")}
            ></img>
            <img
              className="tabs-top"
              src={require("../Assets/Tabs/Group46581.png")}
            ></img>
          </div>
          <img
            className="tabs-tabs-img"
            src={require("../Assets/Tabs/tabs.png")}
          ></img>
        </div>
      </div>
    </section>
  );
};

export default Tabs;
