import React, { useContext } from 'react'
import './Layoutandgrids.css'
import { AppContext } from '../../context/AppContext'


const Layoutandgrids = () => {
    const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };
    return (
        <div className='Layoutandgrids' onClick={()=>{handleClick()}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
            </style>
            <div className='Layoutandgrids-background'>
                <h1>Layout and Grids</h1>
                <hr className='titlebottomborder'></hr>
                <div className='layoutandgrids-elem'>
                    <div className='layoutandgrids-box'>
                        <div className='layoutandgrids-desktop-img'>
                            <img src={require('../Assets/Layouts & Grids/Desktop.png')} width='309px' height='201px'></img>
                        </div>
                        <div className='layoutandgrids-desktop-text'>
                            <h4>Desktop</h4>
                            <p>Column 12 / Width 90px / Gutter 30px</p>
                        </div>
                    </div>
                    <div className='layoutandgrids-box'>
                        <div className='layoutandgrids-tablet-img'>
                            <img src={require('../Assets/Layouts & Grids/Tablet.png')} width='167px' height='201px'></img>
                        </div>
                        <div className='layoutandgrids-tablet-text'>
                        <h4>Tablet</h4>
                        <p>Column 6 / Width 45px / Gutter 24px</p>
                        </div>
                    </div>
                <div className='layoutandgrids-box'>
                    <div className='layoutandgrids-mobile-img'>
                        <img src={require('../Assets/Layouts & Grids/Mobile.png')} width='93px' height='151px'></img>
                    </div>
                    <div className='layoutandgrids-mobile-text'>
                        <h4>Mobile</h4>
                        <p>Column 4 / Width 16px / Gutter 20px</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layoutandgrids