import React, { useContext, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import "./UsesOnBackground.css";
import { AppContext } from "../../context/AppContext";
import dimensions from "../../dimension/dimensions";

const UsesOnBackground = ({setUsesOnBackgroundHeight}) => {
  const context = useContext(AppContext);
  const handleClick = () => {
    context?.setAppData({
      ...context.appData,
      toggleNavbar: false,
    });
  };

  return (
    <section
      className="UsesOnBackground section-height"
      id="UsesOnBackground"
      onClick={() => {
        handleClick();
      }}
    >
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
      </style>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
      </style>
      <div className="UsesOnBackground-background">
        <h1>Uses on Background</h1>
        <div className="UsesOnBackground-elem">
          <div className="contentcontainer-uses">
            <p>
              The full-color logos should be used only on white, black or
              aubergine backgrounds.
            </p>
            <p>
              Avoid using full-color logos on photographs unless the logo sits
              on a black or white area of the image.
            </p>
          </div>
          <img
            className="contentcontainer-uses-img"
            src={require("../Assets/Group46213.png")}
          ></img>
        </div>
      </div>
    </section>
  );
};

export default UsesOnBackground;
