import React, { useContext } from 'react'
import NavBar from '../NavBar/NavBar'
import './Typography.css'
import { AppContext } from '../../context/AppContext'


const Typography = () => {
    const context = useContext(AppContext);
    const handleClick = ()=>{
        context?.setAppData({
            ...context.appData,
            toggleNavbar: false
          });

        };
    return (
        <div className='Typography' onClick={()=>{handleClick()}}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
            </style>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
            </style>
            <div className='Typography-background'>
                <h1>Typography</h1>
                <hr className='titlebottomborder'></hr>
                <div className='Typography-elem'>
                    <div className='Typographycontent-1'>
                        <p>Typeset all text and paragraph text in Open Sans. When using Fincity to set text on embedded or
                            printed collateral for Fincity, always typeset it with optical kerning, set the tracking to -10,
                            and set in title case or sentence case (as determined by the content). <br></br>
                            Do not set in all caps or all lowercase. <br></br>
                            When using Fincity to typeset text on the web, set the letter spacing to 0px for
                            normal paragraphs and -0.2px for hero paragraphs.</p>
                    </div>
                    <div className='Typographycontainer-2'>
                        <div className='Typographyrow-1'>
                            <div className='Typography col-1 row-1'>
                                <h2>H1</h2>
                                <h6>Noto serif Bold, 36/38</h6>
                            </div>
                            <div className='Typography col-2 row-1'>
                                <h1>A better way to work</h1>
                            </div>
                            </div>
                            <div className='Typographyrow-2'>
                            <div className='Typography col-1 row-2'>
                                <h2>H2</h2>
                                <h6>Noto serif Bold, 24/28</h6>
                            </div>
                            <div className='Typography col-2 row-2'>
                                <h2>A better way to work</h2>
                            </div>
                            </div>
                            <div className='Typographyrow-3'>
                            <div className='Typography col-1 row-3'>
                                <h2>H3</h2>
                                <h6>Open sans, 18/21</h6>
                            </div>
                            <div className='Typography col-2 row-3'>
                                <h3>The kind of thing you don’t know you need,
                                    but once you have it, you can’t live without it.</h3>
                            </div>
                            </div>
                            <div className='Typographyrow-4'>
                            <div className='Typography col-1 row-4'>
                                <h2>H4</h2>
                                <h6>Open sans, 12/15</h6>
                            </div>
                            <div className='Typography col-2 row-4'>
                                <h4>After all, every organization needs a little Slack.</h4>
                            </div>
                            </div>
                            <div className='Typographyrow-5'>
                            <div className='Typography col-1 row-5'>
                                <h2>H4</h2>
                                <h6>Open sans, 9/12</h6>
                            </div>
                            <div className='Typography col-2 row-5'>
                                <h5>There is an enormous and heretofore largely unmet
                                    demand for an easier way to coordinate and align
                                    people. That demand is why Fincity is here today,
                                    and why Fincity is being adopted all over the world.</h5>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Typography