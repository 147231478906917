import logo from './logo.svg';
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home/Home';
import Thelogo from './Components/Thelogo/Thelogo';
import UsesOnBackground from './Components/UsesOnBackground/UsesOnBackground';
import LogoMisuse from './Components/LogoMisuse/LogoMisuse';
import Typography from './Components/Typography/Typography';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Spacing from './Components/Spacing/Spacing';
import Layoutandgrids from './Components/Layout&grids/Layoutandgrids';
import ColorPallete from './Components/Color Pallete/ColorPallete';
import FloatingActionButton from './Components/FloatingActionButton/FloatingActionButton';
import RadioToggleButton from './Components/RadioToggleButton/RadioToggleButton';
import Iconography from './Components/Iconography/Iconography';
import Buttons from './Components/Buttons/Buttons';
import Checkboxes from './Components/Checkboxes/Checkboxes';
import Chips from './Components/Chips/Chips';
import Stepper from './Components/Stepper/Stepper';
import Tabs from './Components/Tabs/Tabs';
import Snackbars from './Components/Snackbars/Snackbars';
import Dialogbox from './Components/Dialogbox/Dialogbox';
import BottomSheet from './Components/BottomSheet/BottomSheet';
import Cards from './Components/Cards/Cards';
import DateTimePicker from './Components/DateTimePicker/DateTimePicker';
import TextFieldDropdown from './Components/TextFieldDropdown/TextFieldDropdown';
import DataTable from './Components/DataTable/DataTable';
import Others from './Components/Others/Others';
import { AppContext } from './context/AppContext';
import { useContext,useState } from 'react';
import LogoDropdown from './Components/LogoDorpdown/LogoDropdown';
import ComponentsDropdown from './Components/ComponentsDropdown/ComponentsDropdown';
import dimension from './dimension/dimension';



function App() {

  const context = useContext(AppContext);

  const [Width, Height] = dimension();

  const [appData, setAppData] = useState({
    toggleNavbar: false,
  });

  return (
    <div className="App" style={{minHeight:Height}}>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
      </style>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
      </style>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
      </style>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
      </style>
      <AppContext.Provider value={{ appData, setAppData }}>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/home" element={<Home />} />
            <Route path="/logo" element={<LogoDropdown />} />
            <Route path="/logomisuse" element={<LogoMisuse />} />
            <Route path="/usesonbackground" element={<UsesOnBackground />} />
            <Route path="/spacing" element={<Spacing />} />
            <Route path="/layoutandgrids" element={<Layoutandgrids />} />
            <Route path="/colorpallete" element={<ColorPallete />} />
            <Route
              path="/floatingactionbutton"
              element={<FloatingActionButton />}
            />
            <Route path="/radiotogglebutton" element={<RadioToggleButton />} />
            <Route path="/iconography" element={<Iconography />} />
            <Route path="/buttons" element={<ComponentsDropdown />} />
            <Route path="/checkboxes" element={<Checkboxes />} />
            <Route path="/chips" element={<Chips />} />
            <Route path="/stepper" element={<Stepper />} />
            <Route path="/tabs" element={<Tabs />} />
            <Route path="/snackbars" element={<Snackbars />} />
            <Route path="/dialogbox" element={<Dialogbox />} />
            <Route path="/bottomsheet" element={<BottomSheet />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/datetimepicker" element={<DateTimePicker />} />
            <Route path="/textfielddropdown" element={<TextFieldDropdown />} />
            <Route path="/datatable" element={<DataTable />} />
            <Route path="/others" element={<Others />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
